import React from 'react';
import { ErrorBlock } from 'antd-mobile';

export default function NotFound() {
    return (
        <main className="App">
            <ErrorBlock status="empty" />
        </main>
    );
}
